// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Catalog from './Catalog';
import AdminPanel from './AdminPanel';
import Header from './components/Header';
import './App.css';

function App() {
  return (
    <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/admin" element={<AdminPanel />} />
        </Routes>
      {/* </div> */}
    </Router>
  );
}

export default App;
