// src/Catalog.jsx
import React, { useState, useEffect } from 'react';
import CostumeCard from './CostumeCard';
import Basket from './Basket';
import './Catalog.css'; // Import the new CSS for styling

const Catalog = () => {
  const [costumes, setCostumes] = useState([]);
  const [filteredCostumes, setFilteredCostumes] = useState([]);
  const [basket, setBasket] = useState([]);
  const [titleFilter, setTitleFilter] = useState('');
  const [sizeFilter, setSizeFilter] = useState('');
  const [conditionFilter, setConditionFilter] = useState('');
  const [sortOption, setSortOption] = useState('title');

  const fetchCostumes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/costumes`);
      if (!response.ok) throw new Error('Failed to fetch costumes');
      
      const data = await response.json();
      setCostumes(data);
      setFilteredCostumes(data);
    } catch (error) {
      console.error('Error fetching costumes:', error);
    }
  };

  useEffect(() => {
    fetchCostumes();
  }, []);

  useEffect(() => {
    let filtered = costumes;
    if (titleFilter) {
      filtered = filtered.filter(costume =>
        costume.title.toLowerCase().includes(titleFilter.toLowerCase())
      );
    }
    if (sizeFilter) {
      filtered = filtered.filter(costume =>
        costume.size.toLowerCase().includes(sizeFilter.toLowerCase())
      );
    }
    if (conditionFilter) {
      filtered = filtered.filter(costume =>
        costume.condition.toLowerCase().includes(conditionFilter.toLowerCase())
      );
    }

    filtered = filtered.slice().sort((a, b) => {
      const valueA = a[sortOption]?.toString().toLowerCase();
      const valueB = b[sortOption]?.toString().toLowerCase();
      return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    });

    setFilteredCostumes(filtered);
  }, [titleFilter, sizeFilter, conditionFilter, sortOption, costumes]);

  const addToBasket = (costume) => {
    setBasket([...basket, costume]);
  };

  const removeFromBasket = (costume) => {
    setBasket(basket.filter(item => item.id !== costume.id));
  };

  const handleCheckoutSuccess = () => {
    setBasket([]);
    fetchCostumes();
  };

  return (
    <div>
      {/* Combined Controls for Filters and Sorting */}
      <div className="controls">
        <input
          type="text"
          placeholder="Filter by Title"
          value={titleFilter}
          onChange={(e) => setTitleFilter(e.target.value)}
        />
        <input
          type="text"
          placeholder="Filter by Size"
          value={sizeFilter}
          onChange={(e) => setSizeFilter(e.target.value)}
        />
        <input
          type="text"
          placeholder="Filter by Condition"
          value={conditionFilter}
          onChange={(e) => setConditionFilter(e.target.value)}
        />
        <div className="sort-section">
          <label>Sort by:</label>
          <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
            <option value="title">Title</option>
            <option value="size">Size</option>
            <option value="condition">Condition</option>
          </select>
        </div>
      </div>

      {/* Costume Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredCostumes.map((costume) => (
          <CostumeCard key={costume._id} costume={costume} addToBasket={addToBasket} />
        ))}
      </div>

      {/* Basket Component */}
      <Basket basket={basket} removeFromBasket={removeFromBasket} onCheckoutSuccess={handleCheckoutSuccess} />
    </div>
  );
};

export default Catalog;
