// src/components/Header.js
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the current route is "/catalog"
  const isCatalogPage = location.pathname === '/catalog';

  return (
    <header className="header">
      <Link to="/" className="header-left">
        <img 
          src="/little-corner.jpeg" 
          alt="Logo" 
          className="header-logo" 
        />
        <div className="header-text">
          <h1 className="header-title">Dance Costumes for All</h1>
          <p className="header-subtitle">Connecting costumes with dancers in need</p>
        </div>
      </Link>
      
      {/* Conditionally render the button only if not on the "/catalog" page */}
      {!isCatalogPage && (
        <button className="catalog-button" onClick={() => navigate('/catalog')}>
          View Available Costumes
        </button>
      )}
    </header>
  );
};

export default Header;
