// src/components/Home.js
import React, { useEffect, useState } from 'react';
import './Home.css'; // Import custom styles for layout and formatting

const Home = () => {
    const [content, setContent] = useState({
        missionHeader: '',
        mission: '',
        aboutHeader: '',
        about: '',
        about2: '',
        missionImage: '',
        aboutImage: '',
      });

      useEffect(() => {
        const fetchContent = async () => {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/content`);
          const data = await response.json();
          setContent(data);
        };
        fetchContent();
      }, []);


  return (
    <div className="home-container">
      <section className="mission-section">
        <h2>{content.missionHeader}</h2> 
        <p>{content.mission}</p>
      </section>

      <section className="about-section">
        <h2>{content.aboutHeader}</h2>
        <div className="about-content about-content-right">
          <p>{content.about}</p>
          {content.missionImage && <img src={content.missionImage} alt="About me" className="about-image" />}
        </div>

        <div className="about-content about-content-left">
        {content.aboutImage && <img src={content.aboutImage} alt="Community" className="about-image" />}
        <p>{content.about2}</p>
        </div>
      </section>
    </div>
  );
};

export default Home;
