// src/CostumeCard.jsx
import React, { useState } from 'react';

const CostumeCard = ({ costume, addToBasket }) => {
  const [isFocused, setIsFocused] = useState(false);

  // Define maximum length for truncation when the card is not focused
  const maxDescriptionLength = 50; // Adjust this length as needed

  // Get the first line of the description
  const firstLine = costume.description ? costume.description.split('\n')[0] : '';

  // Truncate the first line if it's too long
  const truncatedDescription = firstLine.length > maxDescriptionLength
    ? firstLine.slice(0, maxDescriptionLength) + '...'
    : firstLine;

  return (
    <div className="border rounded-lg p-4 shadow-md flex flex-col"
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
    >
      <img src={costume.image} alt={costume.title} className="h-full w-full object-cover" />
      <h2 className="mt-2 text-lg font-semibold">{costume.title}</h2>
      <p className="text-sm text-gray-600">Size: {costume.size}</p>
      <p className="text-sm text-gray-600">Condition: {costume.condition}</p>

      {/* Description: show truncated first line or full multiline description based on focus */}
      <p className={`text-sm text-gray-600 mt-2 ${isFocused ? 'multiline' : 'truncate-line'}`}>
        {isFocused ? costume.description : truncatedDescription}
      </p>

      <button 
        className="mt-auto bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
        onClick={() => addToBasket(costume)}
      >
        Add to Basket
      </button>
    </div>
  );
};

export default CostumeCard;
