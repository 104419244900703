// src/Basket.jsx
import React, { useState } from 'react';

const Basket = ({ basket, removeFromBasket, onCheckoutSuccess }) => {
  const [isCheckout, setIsCheckout] = useState(false);
  const [shippingDetails, setShippingDetails] = useState({
    name: '',
    email: '',
    address: '',
    studio: '',
    phone: '' // New phone field
  });
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    setShippingDetails({
      ...shippingDetails,
      [e.target.name]: e.target.value
    });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleCheckout = async () => {
    if (!validateEmail(shippingDetails.email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (!shippingDetails.name || !shippingDetails.email || !shippingDetails.address || !shippingDetails.studio || !shippingDetails.phone) {
      alert("Please fill in all fields.");
      return;
    }

    setEmailError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ shippingDetails, items: basket }),
      });

      if (response.ok) {
        setSuccessMessage('Thank you for your order! Your costumes will be shipped soon.');
        setShippingDetails({ name: '', email: '', address: '', studio: '', phone: '' }); // Clear form
        onCheckoutSuccess();
        setIsCheckout(false);
        basket.length = 0; // Clear the basket items
      } else {
        setSuccessMessage('There was an issue with your checkout. Please try again.');
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage('Failed to connect to the server.');
    }
  };

  return (
    <div className="fixed bottom-0 right-0 m-4 p-4 bg-white shadow-lg rounded-lg w-64">
      <h2 className="text-xl font-bold mb-2">Basket</h2>

      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

      {basket.length === 0 ? (
        <p>Your basket is empty.</p>
      ) : (
        <>
          <ul>
            {basket.map((item) => (
              <li key={item.id} className="mb-2">
                <div className="flex justify-between items-center">
                  <span>{item.title}</span>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => removeFromBasket(item)}
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>

          <div className="mt-4">
            {isCheckout ? (
              <div>
                <div className="mb-2">
                  <label className="block text-sm">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={shippingDetails.name}
                    onChange={handleInputChange}
                    className="border p-2 w-full"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-sm">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={shippingDetails.email}
                    onChange={handleInputChange}
                    className="border p-2 w-full"
                  />
                  {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
                </div>
                <div className="mb-2">
                  <label className="block text-sm">Shipping Address</label>
                  <input
                    type="text"
                    name="address"
                    value={shippingDetails.address}
                    onChange={handleInputChange}
                    className="border p-2 w-full"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-sm">Receiving Studio Name</label>
                  <input
                    type="text"
                    name="studio"
                    value={shippingDetails.studio}
                    onChange={handleInputChange}
                    className="border p-2 w-full"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-sm">Phone Number</label> {/* New phone number field */}
                  <input
                    type="tel"
                    name="phone"
                    value={shippingDetails.phone}
                    onChange={handleInputChange}
                    className="border p-2 w-full"
                  />
                </div>
                <button
                  onClick={handleCheckout}
                  className="bg-green-500 text-white py-2 px-4 rounded mt-2 w-full"
                >
                  Complete Checkout
                </button>
              </div>
            ) : (
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded mt-2 w-full"
                onClick={() => setIsCheckout(true)}
              >
                Proceed to Checkout
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Basket;
